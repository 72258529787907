<template>
  <v-snackbar v-model="show" :color="color">
    <span>
      {{ text }}
    </span>
    <v-btn variant="text" dark @click="snackbarClick">{{ getText }}</v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Snackbar',
  data: () => ({
    show: false,
    text: '',
    color: 'info',
    callback: Function,
    data: {}
  }),
  methods: {
    snackbarClick() {
      if (this.callback) {
        this.callback(this.data);
        this.show = false;
      } else this.show = false;
    }
  },
  computed: {
    getText() {
      return this.callback == undefined ? 'Close' : 'Go';
    }
  },
  created() {
    this.$emitter.on('snackbar', ({ text, color = 'info', calback, data }) => {
      this.text = text;
      this.color = color;
      this.show = true;
      this.callback = calback;
      this.data = data;
    });
  }
};
</script>

<style lang="scss" scoped>
.v-overlay--active:before {
  opacity: 0;
}
.v-snackbar {
  :deep(.v-snackbar__content) {
    justify-content: space-between !important;
    align-items: center !important;
    display: flex !important;
  }
}
</style>
