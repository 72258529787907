const codeMessagesMap = {
  200: 'Applied successfully',
  201: 'Created successfully'
};

const Fetching = {
  install(app) {
    // show snackbar after success/failed requests
    app.config.globalProperties.$processReq = function (
      promise,
      notify_on_ok = true,
      notify_on_err = true
    ) {
      this.processing = true;
      return promise
        .then((res) => {
          if (notify_on_ok) {
            let message = codeMessagesMap[200];
            if (res.data.message) {
              message = res.data.message;
            } else if (codeMessagesMap[res.status]) {
              message = codeMessagesMap[res.status];
            }

            this.$emitter.emit('snackbar', {
              text: message,
              color: 'success'
            });
          }
          return res;
        })
        .catch(this.$processReqError)
        .catch((err) => {
          if (err.response && err.response.code === 423) {
            this.is_locked = true;
            this.is_locked_message =
              err.response.data && err.response.data.message;
          }
          if (notify_on_err) {
            let text = err.message;
            if (
              err.response &&
              (err.response.data.message || err.response.data.detail)
            ) {
              text = err.response.data.message || err.response.data.detail;
            } else {
              if (err.response && err.response.data) {
                for (let prop in err.response.data) {
                  if (
                    err.response.data &&
                    err.response.data[prop] &&
                    Array.isArray(err.response.data[prop]) &&
                    err.response.data[prop].length
                  ) {
                    err.response.data[prop].forEach((obj) => {
                      if (obj.message) {
                        text = obj.message[0];
                      }
                    });
                  }
                }
              }
            }
            if (!text) {
              text = 'Something went wrong. Please try again.';
            }

            this.$emitter.emit('snackbar', { text, color: 'error' });
          }
          throw err;
        })
        .finally(() => {
          this.processing = false;
        });
    };
    app.config.globalProperties.$processReqSilent = function (promise) {
      this.processing = true;
      return promise.catch(this.$processReqError).finally((...args) => {
        this.processing = false;
        return args;
      });
    };
    app.config.globalProperties.$processReqError = function (err) {
      if (
        err.response &&
        (err.response.code === 401 || err.response.status === 401)
      ) {
        // means auth token is invalid
        app.config.globalProperties.$logout();
      }
      throw err;
    };
    app.mixin({
      data: () => ({
        processing: false,
        is_locked: false,
        is_locked_message: ''
      })
    });
  }
};

export default Fetching;
